

















































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { DxSortable } from "devextreme-vue";
import CellInfoTemplate from "./cellInfoTemplate.vue";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { UtilsString } from "@/utils/utils-string";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { UtilsEncript } from "@/utils/utils-encript";
import { dietaDto } from "@/shared/dtos/visualizacion_dieta/dietaDto";
import { DietaUtilsService } from "@/views/pacientes/hist_dietas/service-dieta-utils";

@Component({
  components: {
    CellInfoTemplate,
    DxSortable,
    DialogAddPlatoDieta: () => import("./DialogAddPlatoDieta.vue"),
    EstadisticasVisualizacion: () => import("./EstadisticasVisualizacion.vue"),
    ButonWhithList: () => import("@/components/Buttons/ButonWhithList.vue"),
    TooltipButton: () => import("@/components/Buttons/TooltipButton.vue"),
    DialogEditEstadisticas: () => import("./dialog_edit_estadisticas.vue"),
    DialogRptErrorDieta: () => import("./dialog_rpt_error_dieta.vue"),
    DialogPacienteFichaDataExtends: () =>
      import("./dialog_paciente_ficha_data_extends.vue"),
    DialogDietaFicha: () => import("./dialog_dieta_ficha.vue"),
    DialogDatosUltimaConsulta: () =>
      import("./dialog_datos_ultima_consulta.vue"),
    DashboardsEstadisticas: () => import("./DashboardEstadisticas.vue"),
    DialogRecomendacionesDieta: () =>
      import("./dialog_recomendaciones_dieta.vue"),
    DialogAnotacionesDieta: () => import("./dialog_anotaciones_dieta.vue"),
  },
})
export default class VisualizacionDeLaDieta extends Vue {
  public item_for_copy = null;
  public cargando = true;
  public AddDialogShw = false;
  public ModifiEstadisticasDialogShw = false;
  public add_in: dieta_visualizacion = new dieta_visualizacion();
  public id_dieta: number = 0;
  public id_paciente: number = -1;
  public obj_dieta: dietaDto = new dietaDto({ visible_paciente: false });
  public pegar_dia: boolean = false;
  public shw_create_issue: boolean = false;
  public shw_info_paciente: boolean = false;
  public shw_info_dieta: boolean = false;
  public shw_ultima_consulta: boolean = false;
  public shw_recoemendaciones: boolean = false;
  public shw_anotaciones: boolean = false;

  async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_dieta)) {
      this.id_dieta = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_dieta)
      );
    }
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    dieta_visualizacionModule.getdieta_visualizacions(this.id_dieta);
    this.recalcular_estadisticas();
    await this.objDieta();
    this.cargando = false;
  }

  public async objDieta() {
    if (this.id_paciente === -1) {
      return;
    }
    if (dieta_visualizacionModule.dietas.length === 0) {
      await dieta_visualizacionModule.getdietas_paciente(this.id_paciente);
      this.obj_dieta = dieta_visualizacionModule.dietas.find(
        (x) => x.id === this.id_dieta
      )!;
      if (this.obj_dieta === undefined) {
        this.obj_dieta = new dietaDto();
        this.obj_dieta.visible_paciente = false;
        this.obj_dieta.id = this.id_dieta;
        this.obj_dieta.paciente = this.id_paciente;
      }
    } else {
      let obj = dieta_visualizacionModule.dietas.find(
        (x) => x.id === this.id_dieta
      )!;
      if (obj === undefined) {
        await dieta_visualizacionModule.getdietas_paciente(this.id_paciente);
        this.obj_dieta = dieta_visualizacionModule.dietas.find(
          (x) => x.id === this.id_dieta
        )!;
        if (this.obj_dieta === undefined) {
          this.obj_dieta = new dietaDto();
          this.obj_dieta.visible_paciente = false;
          this.obj_dieta.id = this.id_dieta;
          this.obj_dieta.paciente = this.id_paciente;
        }
      } else {
        this.obj_dieta = obj;
      }
    }
  }

  public get plan() {
    return dieta_visualizacionModule.dieta_visualizacions;
  }

  public operaciones(
    item: any,
    index: number,
    sindex: number,
    operacion: number
  ) {
    switch (operacion) {
      case 1:
        //clone
        let newItem = Object.assign(item);
        newItem.id = 0;
        this.plan[index][sindex].push(newItem);
        let visual_plat: dieta_visualizacion = new dieta_visualizacion(newItem);
        dieta_visualizacionModule
          .guardarplato_dieta(visual_plat)
          .then((x: dieta_visualizacion) => {
            let it = this.plan[index][sindex];
            let upit = it[it.length - 1];
            let nwit = x;
            upit.id = x.id;
            this.recalcular_estadisticas();
          });
        break;
      case 2:
        //delete
        let index_delete = this.plan[index][sindex].findIndex(
          (x: any) => x.id === item.id
        );
        let obj_delete = new dieta_visualizacion(
          this.plan[index][sindex][index_delete]
        );
        dieta_visualizacionModule.eliminarplato_dieta(obj_delete).then(() => {
          this.recalcular_estadisticas();
        });
        this.plan[index][sindex].splice(index_delete, 1);
        break;
      case 3:
        //copy
        this.item_for_copy = item;
        this.pegar_dia = false;
        break;
      case 4:
        //paste
        if (this.pegar_dia) {
          if (this.plan[index][sindex].length === 0) {
            dieta_visualizacionModule.postpegar_comidas_dia({
              id_dia: sindex + 1,
              id_comida: index + 1,
              plato: this.item_for_copy,
            });
            this.item_for_copy = null;
            this.pegar_dia = false;
          } else {
            import("@/shared/services/message-service").then((x) => {
              x.ssmMessageService.confirm(
                "Esta acción remplazará los platos que hay ahora por los que has copiado",
                "¿Quiere remplazar los platos por los que ha copiado?",
                (res) => {
                  if (res) {
                    dieta_visualizacionModule.postpegar_comidas_dia({
                      id_dia: sindex + 1,
                      id_comida: index + 1,
                      plato: this.item_for_copy,
                    });
                    this.item_for_copy = null;
                    this.pegar_dia = false;
                  } else {
                    this.item_for_copy = null;
                    this.pegar_dia = false;
                  }
                }
              );
            });
          }
        } else {
          let newItemCopy: dieta_visualizacion = new dieta_visualizacion(
            //@ts-ignore
            Object.assign(this.item_for_copy)
          );
          newItemCopy.id = 0;
          newItemCopy.id_dia = sindex + 1;
          newItemCopy.id_comida = index + 1;
          newItemCopy.orden = this.plan[index][sindex].length;
          this.plan[index][sindex].push(newItemCopy);
          this.item_for_copy = null;

          dieta_visualizacionModule
            .guardarplato_dieta(newItemCopy)
            .then((x: dieta_visualizacion) => {
              let it = this.plan[index][sindex];
              let upit = it[it.length - 1];
              let nwit = x;
              upit.id = x.id;
              this.recalcular_estadisticas();
            });
        }

        break;
      case 5:
        ///add
        this.add_in = new dieta_visualizacion();
        this.add_in.id_dieta = this.id_dieta;
        this.add_in.id_estructura = 0;
        this.add_in.id_dia = sindex + 1;
        this.add_in.id_comida = index + 1;
        this.add_in.id_tipo_dato = 1;
        //this.plan[index][sindex].push(newIt);
        this.AddDialogShw = true;
        break;

      case 6:
        //copiar dia
        var newItemCopyDay = new dieta_visualizacion();
        newItemCopyDay.id_dieta = this.id_dieta;
        newItemCopyDay.id_estructura = 0;
        newItemCopyDay.id_dia = sindex + 1;
        newItemCopyDay.id_comida = index + 1;
        newItemCopyDay.id_tipo_dato = 1;
        //@ts-ignore
        this.item_for_copy = newItemCopyDay;
        this.pegar_dia = true;
        break;

      case 7:
        //pegar toda la comida para toda la semana
        if (this.pegar_dia) {
          import("@/shared/services/message-service").then((x) => {
            x.ssmMessageService.confirm(
              "Esta acción remplazará los platos que hay ahora por los que has copiado",
              "¿Quiere remplazar los platos por los que ha copiado?",
              (res) => {
                if (res) {
                  dieta_visualizacionModule.postpegar_comida_semana({
                    id_comida: index,
                    plato: this.item_for_copy,
                  });
                  this.item_for_copy = null;
                  this.pegar_dia = false;
                } else {
                  this.item_for_copy = null;
                  this.pegar_dia = false;
                }
              }
            );
          });
        } else {
          let _copia: dieta_visualizacion = new dieta_visualizacion(
            //@ts-ignore
            Object.assign(this.item_for_copy)
          );
          let newItemCopy: dieta_visualizacion = new dieta_visualizacion(
            _copia
          );
          for (let i_dia_semana = 1; i_dia_semana <= 8; i_dia_semana++) {
            newItemCopy.id = 0;
            newItemCopy.id_dia = i_dia_semana;
            newItemCopy.id_comida = index;
            try {
              newItemCopy.orden = this.plan[index - 1][i_dia_semana - 1].length;
            } catch (error) {}

            this.plan[index - 1][i_dia_semana - 1].push(newItemCopy);
            //este if para no duplicarlo en el dia y comida que lo copio

            dieta_visualizacionModule
              .guardarplato_dieta(newItemCopy)
              .then((x: dieta_visualizacion) => {
                let it = this.plan[index - 1][i_dia_semana - 1];
                let upit = it[it.length - 1];
                let nwit = x;
                upit.id = x.id;
              });
            if (
              _copia.id_dia !== newItemCopy.id_dia &&
              _copia.id_comida !== newItemCopy.id_comida
            ) {
            }
          }
          this.refrescar_dieta(false);
          this.item_for_copy = null;
          if (index === _copia.id_comida) {
            dieta_visualizacionModule
              .eliminarplato_dieta(_copia)
              .then(() => {});
          }
        }

      default:
        break;
    }
  }

  public onTaskDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }
  public onTaskDrop(e: any) {
    e.fromData.splice(e.fromIndex, 1);
    e.toData.splice(e.toIndex, 0, e.itemData);
    let identifFrom = e.fromComponent._$element[0].id;
    let identifTo = e.toComponent._$element[0].id;
    let registro_cambiado: boolean = false;
    if (identifFrom !== identifTo) {
      let dia = Number.parseInt(UtilsString.ValueOf(identifTo).split("|")[0]);
      let comida = Number.parseInt(
        UtilsString.ValueOf(identifTo).split("|")[1]
      );
      e.itemData.id_dia = dia;
      e.itemData.id_comida = comida;
      registro_cambiado = true;
    }
    if (e.toIndex !== e.fromIndex) {
      e.itemData.orden = e.toIndex;
      registro_cambiado = true;
    }
    if (registro_cambiado) {
      //llamo a la api
      for (
        let index_orden_plato = 0;
        index_orden_plato < e.toData.length;
        index_orden_plato++
      ) {
        let visual_plat: dieta_visualizacion = new dieta_visualizacion(
          e.toData[index_orden_plato]
        );
        visual_plat.orden = index_orden_plato;
        dieta_visualizacionModule.modificarplato_dieta(visual_plat);
      }
      /*let visual_plat: dieta_visualizacion = new dieta_visualizacion(
        e.itemData
      );
      dieta_visualizacionModule.modificarplato_dieta(visual_plat);*/
    }
  }

  public get opcionesBotonPrintDieta() {
    let btns: buttonOptions[] = [];
    let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Imprimir dieta (Formato 1)";
    btn.EventMethod = this.imprimir_dieta;
    btns.push(btn);

    let btn2: buttonOptions = new buttonOptions();
    btn2.id = 2;
    btn2.title = "Imprimir lista de la compra";
    btn2.EventMethod = this.imprimir_lista_compra_dieta;
    btns.push(btn2);

    let btn3: buttonOptions = new buttonOptions();
    btn3.id = 3;
    btn3.title = "Imprimir recetas";
    btn3.EventMethod = this.imprimir_recetas_dieta;
    btns.push(btn3);

    let btn5: buttonOptions = new buttonOptions();
    btn5.id = 5;
    btn5.title = "Imprimir solo cantidades";
    btn5.EventMethod = () => {
      dieta_visualizacionModule.GetIngredientesRecetasPdf(this.id_dieta);
    };
    btns.push(btn5);

    let btn4: buttonOptions = new buttonOptions();
    btn4.id = 4;
    btn4.title = "Imprimir todo";
    btn4.EventMethod = this.imprimir_todo;
    btns.push(btn4);

    let btn6: buttonOptions = new buttonOptions();
    btn6.id = 6;
    btn6.title = "Imprimir dieta (Formato 2)";
    btn6.EventMethod = () => {
      dieta_visualizacionModule.GetInformeFormato2(this.id_dieta);
    };
    btns.push(btn6);

    return btns;
  }

  public get opcionesBotonEmailDieta() {
    let btns: buttonOptions[] = [];

    let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Enviar dieta (Formato 1)";
    btn.EventMethod = this.enviar_por_email_dieta;
    btns.push(btn);

    let btn2: buttonOptions = new buttonOptions();
    btn2.id = 2;
    btn2.title = "Enviar lista de la compra";
    btn2.EventMethod = this.enviar_por_email_lista_compra;
    btns.push(btn2);

    let btn3: buttonOptions = new buttonOptions();
    btn3.id = 3;
    btn3.title = "Enviar recetas";
    btn3.EventMethod = this.enviar_por_email_recetas;
    btns.push(btn3);

    let btn5: buttonOptions = new buttonOptions();
    btn5.id = 5;
    btn5.title = "Enviar solo cantidades";
    btn5.EventMethod = () => {
      dieta_visualizacionModule.SendSoloCantidades({
        id_dieta: this.id_dieta,
        id_paciente: this.id_paciente,
      });
    };
    btns.push(btn5);

    let btn4: buttonOptions = new buttonOptions();
    btn4.id = 4;
    btn4.title = "Enviar todo";
    btn4.EventMethod = this.enviar_todo;
    btns.push(btn4);

    let btn6: buttonOptions = new buttonOptions();
    btn6.id = 6;
    btn6.title = "Enviar dieta (Formato 2)";
    btn6.EventMethod = () => {
      dieta_visualizacionModule.SendDietaFormato2({
        id_dieta: this.id_dieta,
        id_paciente: this.id_paciente,
      });
    };
    btns.push(btn6);

    return btns;
  }

  public get opcionesBotonEnviarApp() {
    let btns: buttonOptions[] = [];

    let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Enviar dieta a app del paciente";
    btn.EventMethod = this.enviar_a_app;
    btns.push(btn);

    return btns;
  }
  public get opcionesBotonModificarEstadisticas() {
    let btns: buttonOptions[] = [];
    let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Modificar estadística";
    btn.EventMethod = this.modificar_est;
    btns.push(btn);
    return btns;
  }

  public get opcionesBotonRptErrDieta() {
    let btns: buttonOptions[] = [];
    let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Informar de un error";
    btn.EventMethod = this.ShwCreateIssue;
    btns.push(btn);
    return btns;
  }
  public get opcionesBotonInfoPaci() {
    let btns: buttonOptions[] = [];
    let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Información ficha";
    btn.EventMethod = this.ShwInfoPaciente;
    btns.push(btn);

    let btn2: buttonOptions = new buttonOptions();
    btn2.id = 2;
    btn2.title = "Información dieta";
    btn2.EventMethod = this.ShwInfoDieta;
    btns.push(btn2);

    let btn3: buttonOptions = new buttonOptions();
    btn3.id = 3;
    btn3.title = "Información última consulta";
    btn3.EventMethod = this.ShwInfoUltimaConsulta;
    btns.push(btn3);
    return btns;
  }
  public recalcular_estadisticas() {
    dieta_visualizacionModule.getdieta_estadisticas({
      id_dieta: this.id_dieta,
      id_paciente: this.id_paciente,
    });
    dieta_visualizacionModule.getdieta_dashboard({
      id_dieta: this.id_dieta,
      id_paciente: this.id_paciente,
    });
  }

  public refrescar_dieta(show_loading: boolean) {
    if (show_loading) {
      dieta_visualizacionModule.getdieta_visualizacions(this.id_dieta);
    } else {
      dieta_visualizacionModule.getdieta_visualizacions_back(this.id_dieta);
    }

    this.recalcular_estadisticas();
  }

  public ShwCreateIssue() {
    this.shw_create_issue = true;
  }
  public ShwInfoPaciente() {
    this.shw_info_paciente = true;
  }
  public ShwInfoUltimaConsulta() {
    this.shw_ultima_consulta = true;
  }
  public ShwInfoDieta() {
    this.shw_info_dieta = true;
  }

  public modificar_est() {
    this.ModifiEstadisticasDialogShw = true;
  }
  public move_to(element: string) {
    document.getElementById(element)!.scrollIntoView();
  }

  public enviar_a_app() {
    this.shw_info_dieta = false;
    DietaUtilsService.enviar_a_app(this.obj_dieta.id);
    this.objDieta();
  }

  public imprimir_dieta() {
    dieta_visualizacionModule.getpdf_dieta(this.id_dieta);
  }
  public imprimir_lista_compra_dieta() {
    dieta_visualizacionModule.getpdf_lista_compra_dieta(this.id_dieta);
  }
  public imprimir_recetas_dieta() {
    dieta_visualizacionModule.getpdf_Recetas(this.id_dieta);
  }
  public imprimir_todo() {
    this.imprimir_dieta();
    this.imprimir_lista_compra_dieta();
    this.imprimir_recetas_dieta();
  }
  public enviar_por_email_dieta() {
    dieta_visualizacionModule.SendEmailDieta({
      id_dieta: this.id_dieta,
      id_paciente: this.id_paciente,
    });
  }

  public enviar_por_email_lista_compra() {
    dieta_visualizacionModule.SendEmailListaCompra({
      id_dieta: this.id_dieta,
      id_paciente: this.id_paciente,
    });
  }

  public enviar_por_email_recetas() {
    dieta_visualizacionModule.SendEmailRecetas({
      id_dieta: this.id_dieta,
      id_paciente: this.id_paciente,
    });
  }
  public enviar_todo() {
    this.enviar_por_email_dieta();
    this.enviar_por_email_lista_compra();
    this.enviar_por_email_recetas();
  }
}
